import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/element/button";
import { LazyLoadImage } from "react-lazy-load-image-component";
import formatNumber from "../../../helpers/format.number";
import {
  clearAccessoriesInfo,
  getProductInfo,
  getProductList,
  setFilterPage,
} from "../../../redux/reducer/serviceReducer";
import {
  openAccessoriesModal,
  openLoginModal,
} from "../../../redux/reducer/modalReducer";
import { useToast } from "../../../components/common/common.toast";
import { addCart } from "../../../redux/reducer/cartOrderReducer";
import { Skeleton } from "primereact/skeleton";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import { getCartCount } from "../../../redux/reducer/commonReducer";
import { DefaultJustint } from "../../../assets/images";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useState } from "react";
import { Icon } from "@iconify/react";
import scrollToTop from "../../../helpers/scroll.to.top";
import { useRef } from "react";

export default function AccessoriesBody() {
  const toast = useToast();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const showProductRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const shareProductId = searchParams.get("product");

  const {
    token,
    username,
    categoryList,
    role,
    filterBrand,
    filterModel,
    filterYear,
  } = useSelector((state) => state.common);
  const { addCartLoading } = useSelector((state) => state.cart_order);
  const {
    accessoriesList,
    accessoriesCount,
    productLoading,
    filterSearch,
    filterCategory,
    filterPage,
  } = useSelector((state) => state.service);

  const currentYear = new Date().getFullYear();

  const generateYearRange = (startYear, endYear) => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  const handlePageChange = (selectedPage) => {
    dispatch(setFilterPage(selectedPage?.selected));

    window.scrollTo({
      top: 550,
      behavior: "smooth",
    });

    dispatch(
      getProductList({
        start: selectedPage?.selected * 12,
        length: 12,
        service: "accessory",
        search: filterSearch,
        category_id: filterCategory,
        brand_id: filterBrand.map((brand) => brand.id),
        model_id: filterModel.map((model) => model.id),
        model_year:
          filterYear[0] !== 1990 || filterYear[1] !== currentYear
            ? generateYearRange(filterYear[0], filterYear[1])
            : [],
      })
    );
  };

  const handleViewProduct = (id) => {
    dispatch(clearAccessoriesInfo());
    dispatch(openAccessoriesModal());
    dispatch(
      getProductInfo({
        id: id,
      })
    );
  };

  const handleAddCart = (e, id) => {
    e.stopPropagation();

    if (token && username) {
      dispatch(
        addCart({
          product_id: [id],
          quantity: 1,
        })
      )
        .unwrap()
        .then((res) => {
          dispatch(getCartCount());
          toast.success("Add to cart successfully.");
        })
        .catch((ex) => {
          if (ex && ex.response?.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                toast.error(errors[item][0]);
              });
            }
          }
        });
    }
  };

  const handleLogin = (e) => {
    e.stopPropagation();

    dispatch(openLoginModal());
  };

  const handleClickEnquiry = (e, product) => {
    e.stopPropagation();

    const phoneNumber = "60122334402";
    const message = `Hi, I would like to inquire about the product ${product}. Could you provide more details or assistance?`;
    const whatsappURL = `https://wa.me/`;

    window.open(
      whatsappURL + phoneNumber + "?text=" + encodeURIComponent(message)
    );
  };

  useEffect(() => {
    if (shareProductId) {
      handleViewProduct(shareProductId);
      setSearchParams("");
    }
  }, [shareProductId]);

  return (
    <section className="accessories-body" ref={showProductRef}>
      <article className="body-wrap">
        {filterCategory?.length > 0 ? (
          <h4>
            Showing Products for&nbsp;
            {filterCategory.map((categoryId, categoryIndex) => {
              const categoryLabel =
                categoryList.find((category) => category.id === categoryId)
                  ?.name || "Unknown Category";

              return (
                <span key={categoryId}>
                  {categoryLabel}
                  {categoryIndex + 1 !== filterCategory.length ? ", " : ""}
                </span>
              );
            })}
          </h4>
        ) : (
          <h4>Showing All</h4>
        )}
        <section className="accessories-list mt-5">
          {!productLoading
            ? accessoriesList?.length > 0 &&
              accessoriesList.map((accessory, accessoryIndex) => {
                const isVideoFile =
                  accessory.media?.length > 0
                    ? [
                        "mp4",
                        "MP4",
                        "mov",
                        "MOV",
                        "webm",
                        "WEBM",
                        "mkv",
                        "MKV",
                      ].includes(accessory.media[0].url?.split(".").pop())
                    : false;

                return (
                  <article
                    key={accessoryIndex}
                    className="accessories-item pointer"
                    onClick={() => handleViewProduct(accessory.id)}
                  >
                    <section className="accessories-frame">
                      {isVideoFile ? (
                        <video
                          src={
                            accessory.media?.length > 0
                              ? accessory.media[0].url
                              : DefaultJustint
                          }
                          // controls
                        />
                      ) : (
                        <LazyLoadImage
                          src={
                            accessory.media?.length > 0
                              ? accessory.media[0].url
                              : DefaultJustint
                          }
                        />
                      )}
                    </section>
                    <section className="accessories-text">
                      <h6>{accessory.name?.name}</h6>
                      <p>{accessory.model}</p>
                      {role === "agent" && !isNaN(accessory.price) && (
                        <h6 className="--price">
                          RM {formatNumber(parseFloat(accessory.price))} <br />
                          <span>
                            NP: RM {formatNumber(accessory.original_price)}
                          </span>
                        </h6>
                      )}
                    </section>
                    <Button
                      disabled={addCartLoading}
                      btnClassName="add-cart-button accessories"
                      onClick={(e) =>
                        token && username
                          ? accessory.price && accessory.price > 0
                            ? handleAddCart(e, accessory.id)
                            : handleClickEnquiry(e, accessory.name?.name)
                          : handleLogin(e)
                      }
                    >
                      {!isNaN(accessory.price)
                        ? accessory.price && accessory.price > 0
                          ? "Add to Cart"
                          : "Click For Enquiry"
                        : "View Price"}
                    </Button>
                  </article>
                );
              })
            : Array.from(
                { length: width < 550 ? 2 : 3 },
                (_, accessorySkeletonIndex) => (
                  <section className="mt-5" key={accessorySkeletonIndex}>
                    <Skeleton
                      // width="100%"
                      height="293px"
                      className="accessories-item"
                      key={accessorySkeletonIndex}
                    />
                  </section>
                )
              )}
        </section>
        {!productLoading && accessoriesList?.length > 0 && (
          <section className="pagination-wrapper">
            <ReactPaginate
              previousLabel={<Icon icon="teenyicons:left-solid" />}
              nextLabel={<Icon icon="teenyicons:right-solid" />}
              breakLabel={"..."}
              pageCount={Math.ceil(accessoriesCount / 12)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              forcePage={filterPage}
            />
          </section>
        )}
      </article>
    </section>
  );
}
