import { Icon } from "@iconify/react";

export default function DownloadTab({ title, className, href, download }) {
  return (
    <a
      className={`download-file ${className} pointer`}
      href={href}
      download={download}
    >
      <p>{title}</p>
      <article className="download-icon">
        <Icon icon="line-md:download-loop" />
      </article>
    </a>
  );
}
