import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import {
  Lambo,
  ProductVideo,
  Supra,
  WrappingVideo,
} from "../../../assets/videos";

export default function WrappingCompare() {
  const { wrappingCompareList } = useSelector((state) => state.service);

  return (
    <section
      className="w-100 flex flex-column items-center relative"
      style={{ gap: "50px" }}
    >
      <article className="bg-frame">
        <section
          className="w-100 h-100"
          dangerouslySetInnerHTML={{
            __html: `
                <video
                    playsinline
                    autoPlay 
                    muted
                    loop
                    src=${Supra}
                />
            `,
          }}
        ></section>
      </article>
      <h5 className="why-professional">Why are we more professional ?</h5>
      <article className="wrapping-compare">
        {wrappingCompareList.map((compare, compareIndex) => (
          <article
            key={compareIndex}
            className={`compare-item --${compare.value}`}
          >
            <h5>{compare.title}</h5>
            {compare.compare_detail?.map((detail, detailIndex) => (
              <section key={detailIndex}>
                <article className="compare-frame">
                  <LazyLoadImage src={detail.image} alt={detail.description} />
                </article>
                <article
                  className={`compare-text ${
                    detail.minHeight ? "--min-height" : ""
                  }`}
                >
                  <p>{detail.description}</p>
                </article>
              </section>
            ))}
          </article>
        ))}
      </article>
    </section>
  );
}
