import { Form, Formik } from "formik";
import Input from "../../../components/element/input";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useEffect } from "react";
import {
  getCommissionList,
  getProfile,
  updateProfile,
} from "../../../redux/reducer/profileCommissionReducer";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import * as Yup from "yup";
import Button from "../../../components/element/button";
import Lottie from "lottie-react";
import { Empty, Loading } from "../../../assets/lotties";
import { getOrderList } from "../../../redux/reducer/cartOrderReducer";
import { useToast } from "../../../components/common/common.toast";
import formatNumber from "../../../helpers/format.number";

export default function ProfileCommissionForm({ locateAt }) {
  const toast = useToast();
  const dispatch = useDispatch();
  const { token, username } = useSelector((state) => state.common);
  const { profileInfo, loading, commissionList, updateLoading } = useSelector(
    (state) => state.profile_commission
  );

  const formatDate = (date) => {
    return dayjs(date, "DD MMM YYYY HH:mm").format("DD MMM YYYY");
  };

  const handleDateChange = (date, dateString, setFieldValue) => {
    if (date && date.isValid()) {
      setFieldValue("birthday_date", dateString);
    }
  };

  const profileSchema = Yup.object().shape({
    contact_no: Yup.string().matches(
      /^(11\d{7,8}|1[0-9]\d{7})$/,
      "Please enter a valid phone number"
    ),
  });

  const profileCommissionForm = {
    profile: {
      title: "My Profile",
      api: {
        get: getProfile,
      },
      initial_form: {
        id: profileInfo?.id ?? "",
        name: profileInfo?.name ?? "",
        contact_no: profileInfo?.phone_number
          ? profileInfo.phone_number?.startsWith("+60")
            ? profileInfo.phone_number.slice(3)
            : profileInfo.phone_number
          : "",
        account_type: profileInfo?.account_type ?? "",
        register_date: profileInfo?.created_at
          ? formatDate(profileInfo.created_at)
          : "",
        birthday_date: profileInfo?.dob ?? "",
      },
      schema: profileSchema,
      form_list: [
        {
          type: "input",
          label: "Full Name",
          placeholder: "eg. John Ma",
          name: "name",
        },
        {
          type: "input",
          label: "Contact No.",
          placeholder: "123456789",
          name: "contact_no",
        },
        {
          type: "input",
          label: "Birthday Date",
          name: "birthday_date",
        },
        {
          type: "input",
          label: "Account Type",
          placeholder: "eg. Agent",
          name: "account_type",
          disabled: true,
        },
        {
          type: "input",
          label: "Register Date",
          name: "register_date",
          disabled: true,
        },
      ],
    },
    commission: {
      title: "My Commission",
      api: {
        get: getCommissionList,
      },
      commission_list: [
        {
          label: "Customer Name",
          value: "customer_name",
        },
        {
          label: "Chassis No",
          value: "car_chassis_number",
        },
        {
          label: "Total Item",
          value: "number_of_items",
        },
        {
          type: "price",
          label: "Total Sales",
          value: "total_sales",
        },
        {
          type: "price",
          label: "Total Agent Price",
          value: "total_agent_price",
        },
        {
          className: "--highlight",
          type: "price",
          label: "Total Commission",
          value: "commission",
        },
      ],
    },
  };

  const handleSubmit = (values, setFieldError) => {
    dispatch(
      updateProfile({
        id: values.id,
        ...(values.birthday_date ? { dob: values.birthday_date } : {}),
        ...(values.contact_no
          ? { phone_number: `+60${values.contact_no}` }
          : {}),
        ...(values.name ? { name: values.name } : {}),
      })
    )
      .unwrap()
      .then((res) => {
        toast.success("Save profile successfully.");
      })
      .catch((ex) => {
        if (ex && ex.response?.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      });
  };

  useEffect(() => {
    if (token && username) {
      dispatch(
        profileCommissionForm[locateAt]?.api.get({
          type: "agent",
          length: 9999,
        })
      );
    }
  }, [token, username, locateAt]);

  return (
    <article
      className="w-100 flex flex-column items-center"
      style={{ gap: "50px" }}
    >
      <section className="profile-commission-form">
        <h5>{profileCommissionForm[locateAt].title}</h5>
        <hr />
        {!loading ? (
          <Formik
            initialValues={{
              id: profileInfo?.id ?? "",
              name: profileInfo?.name ?? "",
              contact_no: profileInfo?.phone_number
                ? profileInfo.phone_number?.startsWith("+60")
                  ? profileInfo.phone_number.slice(3)
                  : profileInfo.phone_number
                : "",
              account_type: profileInfo?.account_type ?? "",
              register_date: profileInfo?.created_at
                ? formatDate(profileInfo.created_at)
                : "",
              birthday_date: profileInfo?.dob ?? "",
            }}
            validationSchema={profileCommissionForm[locateAt].schema}
            onSubmit={(values, { setFieldError }) => {
              handleSubmit(values, setFieldError);
            }}
          >
            {({ isValid, values, errors, setFieldValue }) => {
              const dateValue =
                values && values["birthday_date"]
                  ? dayjs(values["birthday_date"])
                  : "";

              return (
                <Form className="form-list">
                  {locateAt === "commission" ? (
                    commissionList?.length > 0 ? (
                      commissionList.map((commission, commissionIndex) => (
                        <article
                          className={`form-item --${locateAt}`}
                          key={commissionIndex}
                        >
                          <section className="flex items-center gap-3">
                            <Icon
                              icon="mdi:date-range"
                              className="--calendar"
                            />
                            <h6 className="--case-id">
                              {commission.created_at} -{" "}
                              {commission.invoice_number}
                            </h6>
                          </section>
                          <section className="info-list">
                            {profileCommissionForm[
                              locateAt
                            ].commission_list.map((list, listIndex) => (
                              <article
                                className="flex items-center"
                                key={listIndex}
                              >
                                <h6 className={list.className ?? ""}>
                                  {list.label} :
                                </h6>
                                <p className={list.className ?? ""}>
                                  {list.className ? "RM" : ""}&nbsp;
                                  {list.type === "price"
                                    ? formatNumber(
                                        list.label === "Total Commission"
                                          ? commission[list.value]
                                          : parseFloat(commission[list.value])
                                      )
                                    : commission[list.value]}
                                </p>
                              </article>
                            ))}
                          </section>
                          {commission.payment_slip?.length > 0 && (
                            <>
                              <a
                                href={commission.payment_slip[0].url}
                                target="_blank"
                                className="flex items-center gap-3"
                              >
                                <Icon icon="f7:doc-circle" className="--file" />
                                <span>
                                  {commission.payment_slip[0]?.display_name ??
                                    ""}
                                </span>
                              </a>
                              <section className="fyi">
                                <Icon icon="eva:info-outline" />
                                <p>
                                  Payment date will be on 15th of the month{" "}
                                </p>
                              </section>
                            </>
                          )}
                          {commissionIndex + 1 !== commissionList?.length ? (
                            <hr className="--grey mb-5" />
                          ) : (
                            <article className="mb-1"></article>
                          )}
                        </article>
                      ))
                    ) : (
                      <article className="w-100 minh-480 flex flex-column items-center">
                        <article className="common-lottie --empty">
                          <Lottie loop={2} animationData={Empty} />
                        </article>
                        <h6 className="--loading text-center">No data found</h6>
                      </article>
                    )
                  ) : (
                    profileCommissionForm[locateAt].form_list.map(
                      (form, formIndex) => (
                        <article
                          className={`form-item --${locateAt} ${
                            form.disabled ? "disabled" : ""
                          }`}
                          key={formIndex}
                        >
                          {form.name === "birthday_date" ? (
                            <article className="element _text mb-4">
                              <section>
                                <label className="--round-field">
                                  {form.label}
                                </label>
                              </section>
                              <DatePicker
                                value={dateValue}
                                onChange={(date, dateString) => {
                                  handleDateChange(
                                    date,
                                    dateString,
                                    setFieldValue
                                  );
                                }}
                                placeholder="DD-MM-YYYY"
                                format="YYYY-MM-DD"
                              />
                            </article>
                          ) : (
                            <Input
                              labelClassName="--round-field"
                              inputClassName={
                                form.name === "contact_no" ? "phone_no" : ""
                              }
                              label={form.label}
                              as="round-field"
                              name={form.name}
                              placeholder={form.placeholder}
                              disabled={form.disabled}
                              prefix={form.name === "contact_no" ? "+60" : ""}
                            />
                          )}
                        </article>
                      )
                    )
                  )}
                  {locateAt === "profile" && (
                    <Button
                      className="flex justify-end"
                      disabled={!isValid || updateLoading}
                      btnClassName="profile-button"
                      type="submit"
                    >
                      Save Profile
                    </Button>
                  )}
                </Form>
              );
            }}
          </Formik>
        ) : (
          <article className="w-100 minh-480 flex flex-column items-center text-center">
            <article className="common-lottie">
              <Lottie animationData={Loading} />
            </article>
            <h6 className="--loading text-center">Loading ..</h6>
          </article>
        )}
      </section>
    </article>
  );
}
