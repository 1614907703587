import { useSelector } from "react-redux";

export default function Numbers() {
  const { numberList } = useSelector((state) => state.common);

  return (
    <article className="w-100 flex flex-column items-center">
      <section className="numbers">
        <article className="numbers-grid">
          {numberList.map((number, numberIndex) => (
            <section className="numbers-item" key={numberIndex}>
              <h2>
                {typeof number.number === "number"
                  ? parseFloat(number.number).toLocaleString()
                  : number.number}
              </h2>
              <h6>{number.label}</h6>
              <p>{number.description}</p>
            </section>
          ))}
        </article>
      </section>
    </article>
  );
}
