import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/element/button";
import CommonPackage from "../../../components/common/common.package";
import ChooseTypeVlt from "./choose.type.vlt";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { addCart } from "../../../redux/reducer/cartOrderReducer";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useToast } from "../../../components/common/common.toast";
import { setSelectedTintedPackage } from "../../../redux/reducer/serviceReducer";
import CommonViewPrice from "../../../components/common/common.view.price";
import { useRef } from "react";
import CommonStep from "../../../components/common/common.step";
import { getCartCount } from "../../../redux/reducer/commonReducer";
import ChoosePackageOrAlacarte from "../../../components/common/choose.package.alacarte";
import formatNumber from "../../../helpers/format.number";

export default function TintedPackage() {
  const toast = useToast();
  const formikRef = useRef();
  const dispatch = useDispatch();

  const {
    tintedPackageList,
    selectedTintedSeries,
    selectedTintedType,
    selectedTintedPackage,
  } = useSelector((state) => state.service);
  const { addCartLoading } = useSelector((state) => state.cart_order);
  const { selectedBodyType, selectedPackageOrAlacarte, token, username } =
    useSelector((state) => state.common);

  const [packageList, setPackageList] = useState({});

  const tintedFormSchema = Yup.object().shape({
    windscreen_type: Yup.string().when("is_package", {
      is: true,
      then: (schema) => schema.required("Windscreen type is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    windscreen_vlt: Yup.string().when("windscreen_type", {
      is: (type) => type !== undefined,
      then: (schema) => schema.required("Windscreen VLT is required"),
      otherwise: (schema) => schema.notRequired(),
    }),

    rear_door_type: Yup.string().when("is_package", {
      is: true,
      then: (schema) => schema.required("Rear side door type is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    rear_door_vlt: Yup.string().when("rear_door_type", {
      is: (type) => type !== undefined,
      then: (schema) => schema.required("Rear side door VLT is required"),
      otherwise: (schema) => schema.notRequired(),
    }),

    front_door_type: Yup.string().when("is_package", {
      is: true,
      then: (schema) => schema.required("Front side door type is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    front_door_vlt: Yup.string().when("front_door_type", {
      is: (type) => type !== undefined,
      then: (schema) => schema.required("Front side door VLT is required"),
      otherwise: (schema) => schema.notRequired(),
    }),

    rear_glass_type: Yup.string().when("is_package", {
      is: true,
      then: (schema) => schema.required("Rear glass type is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    rear_glass_vlt: Yup.string().when("rear_glass_type", {
      is: (type) => type !== undefined,
      then: (schema) => schema.required("Rear glass VLT is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const tintedForm = {
    is_package: false,
    windscreen_id: "",
    windscreen_price: 0,
    windscreen_type: "",
    windscreen_vlt: "",

    rear_door_id: "",
    rear_door_price: 0,
    rear_door_type: "",
    rear_door_vlt: "",

    front_door_id: "",
    front_door_price: 0,
    front_door_type: "",
    front_door_vlt: "",

    rear_glass_id: "",
    rear_glass_price: 0,
    rear_glass_type: "",
    rear_glass_vlt: "",
  };

  const handleSubmit = (values, setFieldError, resetForm) => {
    const alacarteId = [
      values.windscreen_id,
      values.rear_door_id,
      values.front_door_id,
      values.rear_glass_id,
    ].filter((id) => id);

    dispatch(
      addCart({
        product_id: selectedTintedPackage.id
          ? [selectedTintedPackage.id]
          : alacarteId,
        quantity: 1,
        options: {
          ...(values.windscreen_type &&
            values.windscreen_vlt && {
              windscreen: {
                type: values.windscreen_type,
                VLT: values.windscreen_vlt,
                ...(values.windscreen_price &&
                  values.windscreen_price > 0 && {
                    price: values.windscreen_price,
                  }),
              },
            }),
          ...(values.rear_door_type &&
            values.rear_door_vlt && {
              rear_side_door: {
                type: values.rear_door_type,
                VLT: values.rear_door_vlt,
                ...(values.rear_door_price &&
                  values.rear_door_price > 0 && {
                    price: values.rear_door_price,
                  }),
              },
            }),
          ...(values.front_door_type &&
            values.front_door_vlt && {
              front_side_door: {
                type: values.front_door_type,
                VLT: values.front_door_vlt,
                ...(values.front_door_price &&
                  values.front_door_price > 0 && {
                    price: values.front_door_price,
                  }),
              },
            }),
          ...(values.rear_glass_type &&
            values.rear_glass_vlt && {
              rear_glass: {
                type: values.rear_glass_type,
                VLT: values.rear_glass_vlt,
                ...(values.rear_glass_price &&
                  values.rear_glass_price > 0 && {
                    price: values.rear_glass_price,
                  }),
              },
            }),
        },
      })
    )
      .unwrap()
      .then((res) => {
        resetForm();
        dispatch(getCartCount());
        dispatch(setSelectedTintedPackage({}));
        toast.success("Add to cart successfully.");
      })
      .catch((ex) => {
        if (ex && ex.response?.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              toast.error(errors[item][0]);
            });
          }
        }
      });
  };

  useEffect(() => {
    formikRef?.current?.resetForm();

    if (tintedPackageList[selectedTintedSeries]) {
      if (tintedPackageList[selectedTintedSeries][selectedTintedType]) {
        if (
          tintedPackageList[selectedTintedSeries][selectedTintedType][
            selectedBodyType
          ]?.length > 0
        ) {
          setPackageList(
            tintedPackageList[selectedTintedSeries][selectedTintedType][
              selectedBodyType
            ]
          );
        } else {
          setPackageList([]);
        }
      } else {
        setPackageList([]);
      }
    } else {
      setPackageList([]);
    }
  }, [
    tintedPackageList,
    selectedTintedSeries,
    selectedTintedType,
    selectedBodyType,
  ]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={tintedForm}
      validationSchema={tintedFormSchema}
      onSubmit={(values, { setFieldError, setFieldValue, resetForm }) => {
        handleSubmit(values, setFieldError, resetForm);
      }}
      enableReinitialize={true}
    >
      {({ values, errors, isValid, resetForm, setFieldValue }) => (
        <Form
          className="w-100 flex flex-column items-center"
          style={{ gap: "100px", maxWidth: "1233px" }}
        >
          <CommonStep
            no={4}
            isSelecting={true}
            title="Select Alacarte Or Package"
            subtitle="Available Packages Listed Below"
            style={{ marginBottom: "-8em" }}
          />
          <ChoosePackageOrAlacarte
            locateAt="tinted"
            resetForm={resetForm}
            setFieldValue={setFieldValue}
          />
          <section className="tinted-package">
            {selectedPackageOrAlacarte === "package" && (
              <article className="package-first">
                <article className="text-center">
                  <h5>Go Full Package</h5>
                  {selectedTintedPackage.description && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: selectedTintedPackage.description,
                      }}
                    ></p>
                  )}
                </article>
                <CommonPackage
                  packageList={packageList}
                  className="tinted"
                  resetForm={resetForm}
                  setFieldValue={setFieldValue}
                />
              </article>
            )}
            <ChooseTypeVlt />
            {token && username ? (
              <Button
                btnClassName="total-button tinted"
                disabled={
                  !isValid ||
                  parseFloat(selectedTintedPackage.price || 0) +
                    values.windscreen_price +
                    values.front_door_price +
                    values.rear_door_price +
                    values.rear_glass_price ===
                    0 ||
                  addCartLoading
                }
                type="submit"
              >
                <>
                  <h5>
                    Total RM{" "}
                    {formatNumber(
                      parseFloat(selectedTintedPackage.price || 0) +
                        values.windscreen_price +
                        values.front_door_price +
                        values.rear_door_price +
                        values.rear_glass_price
                    )}
                  </h5>
                  <p>Add to Cart</p>
                </>
              </Button>
            ) : (
              <CommonViewPrice />
            )}
          </section>
        </Form>
      )}
    </Formik>
  );
}
