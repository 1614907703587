import Button from "../element/button";
import { Swiper, SwiperSlide } from "swiper/react";
import { DefaultJustint, SampleGrey } from "../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTintedPackage } from "../../redux/reducer/serviceReducer";
import { addCart } from "../../redux/reducer/cartOrderReducer";
import { Skeleton } from "primereact/skeleton";
import useWindowDimensions from "../hook/use.window.dimensions";
import { useToast } from "./common.toast";
import { openLoginModal } from "../../redux/reducer/modalReducer";
import { useEffect } from "react";
import { getCartCount } from "../../redux/reducer/commonReducer";
import formatNumber from "../../helpers/format.number";

export default function CommonPackage({
  packageList,
  className,
  resetForm,
  setFieldValue,
}) {
  const toast = useToast();
  const dispatch = useDispatch();

  const { token, username } = useSelector((state) => state.common);
  const { addCartLoading } = useSelector((state) => state.cart_order);
  const {
    selectedTintedPackage,
    selectedTintedSeries,
    selectedTintedType,
    tintedLoading,
    wrappingLoading,
  } = useSelector((state) => state.service);

  const handleSelectPackage = (item) => {
    if (className === "tinted") {
      resetForm();
    }

    if (selectedTintedPackage.name?.name === item.name?.name) {
      dispatch(setSelectedTintedPackage({}));
      if (className === "tinted") {
        setFieldValue("is_package", false);
      }
    } else {
      dispatch(setSelectedTintedPackage(item));
      if (className === "tinted") {
        setFieldValue("is_package", true);
      }
    }
  };

  const handleAddCart = (id) => {
    if (token && username) {
      dispatch(
        addCart({
          product_id: [id],
          quantity: 1,
        })
      )
        .unwrap()
        .then((res) => {
          dispatch(getCartCount());
          toast.success("Add to cart successfully.");
        })
        .catch((ex) => {
          if (ex && ex.response?.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                toast.error(errors[item][0]);
              });
            }
          }
        });
    } else {
      dispatch(openLoginModal());
    }
  };

  useEffect(() => {
    if (className === "tinted") {
      resetForm();
      setFieldValue("is_package", false);
      dispatch(setSelectedTintedPackage({}));
    }
  }, [selectedTintedSeries, selectedTintedType]);

  return (
    <section className={`common-package ${className}`}>
      <Swiper
        className="package-list"
        slidesPerView="auto"
        spaceBetween={35}
        grabCursor={true}
      >
        {!(className === "tinted" ? tintedLoading : wrappingLoading)
          ? packageList?.length > 0 &&
            packageList.map((pack, packIndex) => (
              <SwiperSlide
                key={packIndex}
                className={`pointer package-item ${
                  selectedTintedPackage.id === pack.id ? "--active" : ""
                }`}
                onClick={() => handleSelectPackage(pack)}
              >
                <section
                  className="package-detail pointer"
                  style={{
                    background: `url(${
                      pack.media?.length > 0
                        ? pack.media[0].url
                        : DefaultJustint
                    })`,
                  }}
                >
                  <article className="package-bg">
                    <h5>{pack.name.name}</h5>
                  </article>
                  {(!isNaN(pack.price) || pack.name.warranty_period) && (
                    <article className="package-bg --solid">
                      {!isNaN(pack.price) && (
                        <h5 className="--price">
                          RM {formatNumber(parseFloat(pack.price))}
                        </h5>
                      )}
                      {pack.name.warranty_period && (
                        <p>{pack.name.warranty_period} Warranty</p>
                      )}
                    </article>
                  )}
                </section>
                {className === "wrapping" && (
                  <Button
                    disabled={addCartLoading}
                    onClick={() => handleAddCart(pack.id)}
                    btnClassName={`add-cart-button package ${
                      selectedTintedPackage.id === pack.id
                        ? "--selected"
                        : "--none"
                    }`}
                  >
                    {!isNaN(pack.price) ? "Add to Cart" : "View Price"}
                  </Button>
                )}
              </SwiperSlide>
            ))
          : Array.from({ length: 5 }, (_, packSkeletonIndex) => (
              <Skeleton key={packSkeletonIndex} className="package-detail" />
            ))}
      </Swiper>
    </section>
  );
}
