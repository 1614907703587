import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Appearance,
  Bubbling,
  Cracking,
  Durability,
  Hazing,
  Heating,
  Maintenance,
  Peeling,
  Protection,
  SampleWrapping,
  Staining,
} from "../../assets/images";
import apiService from "../../services/api.service";

// export const getProductList = createAsyncThunk('getProductList', async (payload, thunkAPI) => {
//   try {
//     const response = await apiService.getProductList(payload);
//     return response;
//   } catch (error) {
//     return thunkAPI.rejectWithValue(error);
//   }}
// );

// let currentAbortController = null;

// export const getProductList = createAsyncThunk(
//   "getProductList",
//   async (payload, thunkAPI) => {
//     try {
//       if (currentAbortController && !currentAbortController.signal.aborted) {
//         currentAbortController.abort();
//       }

//       currentAbortController = new AbortController();
//       const signal = currentAbortController.signal;

//       const response = await apiService.getProductList(payload, { signal });

//       currentAbortController = null;

//       return response;
//     } catch (error) {
//       if (error.name === "AbortError") {
//       } else {
//         currentAbortController = null;
//         return thunkAPI.rejectWithValue(error);
//       }
//     }
//   }
// );

export const getTintedList = createAsyncThunk(
  "getTintedList",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getProductList(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getPpfList = createAsyncThunk(
  "getPpfList",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getProductList(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getWrappingList = createAsyncThunk(
  "getWrappingList",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getProductList(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCoatingList = createAsyncThunk(
  "getCoatingList",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getProductList(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getProductList = createAsyncThunk(
  "getProductList",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getProductList(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getProductInfo = createAsyncThunk(
  "getProductInfo",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getProductInfo(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const claimWindscreen = createAsyncThunk(
  "claimWindscreen",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.claimWindscreen(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const renewInsurance = createAsyncThunk(
  "renewInsurance",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.renewInsurance(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const initialData = {
  //tinted
  selectedTintedSeries: "jt_series",
  selectedTintedType: "solar",
  // selectedTintedBodyType: 'standard',
  selectedTintedPackage: {},
  tintedSeriesList: {
    "JT Series": [
      {
        series_name: "JT Series",
        series_value: "jt_series",
        type_name: "Solar",
        type_value: "solar",
        file_name: "JT Solar Series",
        file_image: "",
      },
      {
        series_name: "JT Series",
        series_value: "jt_series",
        type_name: "Security",
        type_value: "security",
        file_name: "JT Security Series",
        file_image: "",
      },
    ],
    "Solar Gard": [
      {
        series_name: "Solar Gard",
        series_value: "solar_gard",
        type_name: "LX Series",
        type_value: "lx_series",
        file_name: "Solar Gard LX Series",
        file_image: "",
      },
      {
        series_name: "Solar Gard",
        series_value: "solar_gard",
        type_name: "Black Phantom",
        type_value: "black_phantom",
        file_name: "Solar Gard BP Series",
        file_image: "",
      },
    ],
  },
  tintedPackageList: {},
  tintedAlacarteList: {},

  //ppf
  warrantyList: [
    {
      label: "Cracking",
      image: Cracking,
    },
    {
      label: "Bubbling",
      image: Bubbling,
    },
    {
      label: "Peeling",
      image: Peeling,
    },
    {
      label: "Hazing",
      image: Hazing,
    },
    {
      label: "Staining",
      image: Staining,
    },
  ],
  ppfPackageList: {},
  ppfAlacarteList: {},

  //wrapping
  wrappingList: [
    {
      label: "Protection",
      image: Protection,
      description:
        "Colored TPU PPF film helps Protect the car's surface from stone chips, scratches, and other environmental damages",
    },
    {
      label: "Appearance",
      image: Appearance,
      description:
        "Colored TPU PPF film helps Protect the car's surface from stone chips, scratches, and other environmental damages",
    },
    {
      label: "Self-heating",
      image: Heating,
      description:
        "Colored TPU PPF film helps Protect the car's surface from stone chips, scratches, and other environmental damages",
    },
    {
      label: "Durability",
      image: Durability,
      description:
        "Colored TPU PPF film helps Protect the car's surface from stone chips, scratches, and other environmental damages",
    },
    {
      label: "Easy maintenance",
      image: Maintenance,
      description:
        "Colored TPU PPF film helps Protect the car's surface from stone chips, scratches, and other environmental damages",
    },
  ],
  wrappingPackageList: {},
  wrappingCompareList: [
    {
      title: "Others color changing films",
      compare_detail: [
        {
          image: SampleWrapping,
          description:
            "It is easy to drop glue and damage the original car paint",
        },
        {
          image: SampleWrapping,
          description: "Inferior film is east to delaminate and shrink edges",
        },
        {
          image: SampleWrapping,
          description: "Easy to Splicing Bubbles !",
        },
      ],
      value: "others",
    },
    {
      title: "Our Color Changing Films",
      compare_detail: [
        {
          image: SampleWrapping,
          description: "No Glue / No Damage to the Original Car Paint",
          minHeight: window.innerWidth < 480 ? true : false,
        },
        {
          image: SampleWrapping,
          description: "No Delamination, No Edge Contraction",
          minHeight: true,
        },
        {
          image: SampleWrapping,
          description: "Splicing Without Bubbles",
        },
      ],
      value: "ours",
    },
  ],

  //coating
  coatingAlacarteTable: [
    {
      title: "Type of Coating",
      value: "type",
    },
    {
      title: "Standard",
      value: "standard",
    },
    {
      title: "SUV",
      value: "suv",
    },
    {
      title: "MPV",
      value: "mpv",
    },
  ],
  coatingPackageList: {},
  coatingAlacarteList: {},

  //accessories
  accessoriesList: [],
  accessoriesInfo: {},
  accessoriesCount: 0,
  filterPage: 1,
  filterSearch: "",
  filterCategory: [],

  tintedLoading: false,
  ppfLoading: false,
  wrappingLoading: false,
  coatingLoading: false,
  productLoading: false,
  productInfoLoading: false,
  submitLoading: false,
};

function groupTintedProducts(products, type) {
  const grouped = {};

  const filteredProducts = products.filter(
    (prod) => prod.is_package.toString() === type.toString()
  );

  filteredProducts.forEach((prod) => {
    const series = prod.series?.category?.toLowerCase().replace(/\s+/g, "_");
    const seriesName = prod.series?.name?.toLowerCase().replace(/\s+/g, "_");
    const bodyType = prod.category?.name?.toLowerCase().replace(/\s+/g, "_");

    if (!grouped[series]) {
      grouped[series] = {};
    }
    if (!grouped[series][seriesName]) {
      grouped[series][seriesName] = {
        standard: [],
        luxury: [],
        suv: [],
        mpv: [],
      };
    }
    if (grouped[series][seriesName][bodyType] === undefined) {
      grouped[series][seriesName][bodyType] = [];
    }
    grouped[series][seriesName][bodyType].push(prod);
  });

  return grouped;
}

function groupProducts(products, type) {
  const grouped = {};

  const filteredProducts = products.filter(
    (prod) => prod.is_package.toString() === type.toString()
  );

  filteredProducts.forEach((prod) => {
    const bodyType = prod.category?.name.toLowerCase().replace(/\s+/g, "_");

    if (!grouped[bodyType]) {
      grouped[bodyType] = [];
    }

    grouped[bodyType].push(prod);
  });

  return grouped;
}

function groupProductsName(products, type) {
  const grouped = {};

  const filteredProducts = products.filter(
    (prod) => prod.is_package.toString() === type.toString()
  );

  filteredProducts.forEach((prod) => {
    const name = prod.name.name;
    const bodyType = prod.category?.name?.toLowerCase().replace(/\s+/g, "_");
    const idKey = bodyType + "_id";
    const priceKey = bodyType + "_price";
    const goldPriceKey = bodyType + "_gold_price";
    const silverPriceKey = bodyType + "_silver_price";
    const bronzePriceKey = bodyType + "_bronze_price";

    if (!grouped[name]) {
      grouped[name] = {
        name: name,
        standard_id: null,
        suv_id: null,
        mpv_id: null,
        standard_price: 0,
        suv_price: 0,
        mpv_price: 0,
        standard_gold_price: 0,
        standard_silver_price: 0,
        standard_bronze_price: 0,
        suv_gold_price: 0,
        suv_silver_price: 0,
        suv_bronze_price: 0,
        mpv_gold_price: 0,
        mpv_silver_price: 0,
        mpv_bronze_price: 0,
      };
    }

    grouped[name][idKey] = prod.id;

    grouped[name][priceKey] = prod.price;
    grouped[name][goldPriceKey] = prod.gold_price;
    grouped[name][silverPriceKey] = prod.silver_price;
    grouped[name][bronzePriceKey] = prod.bronze_price;
  });

  return Object.values(grouped);
}

const dataSlice = createSlice({
  name: "service",
  initialState: initialData,
  reducers: {
    setSelectedTintedSeries: (state, action) => {
      state.selectedTintedSeries = action.payload;
    },
    setSelectedTintedType: (state, action) => {
      state.selectedTintedType = action.payload;
    },
    // setSelectedTintedBodyType: (state, action) => {
    //   state.selectedTintedBodyType = action.payload;
    // },
    setSelectedTintedPackage: (state, action) => {
      state.selectedTintedPackage = action.payload;
    },
    clearAccessoriesInfo: (state, action) => {
      state.accessoriesInfo = {};
    },

    setFilterPage: (state, action) => {
      state.filterPage = action.payload;
    },
    setFilterSearch: (state, action) => {
      state.filterSearch = action.payload;
    },
    setFilterCategory: (state, action) => {
      state.filterCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTintedList.pending, (state) => {
        state.tintedLoading = true;
      })
      .addCase(getTintedList.fulfilled, (state, action) => {
        state.tintedLoading = false;
        state.tintedPackageList =
          action.payload.data.packages?.length > 0 &&
          action.payload.data.packages[0].service === "tinted"
            ? groupTintedProducts(action.payload.data.packages, "true")
            : [];
        state.tintedAlacarteList =
          action.payload.data.packages?.length > 0 &&
          action.payload.data.packages[0].service === "tinted"
            ? groupTintedProducts(action.payload.data.packages, "false")
            : [];
      })
      .addCase(getTintedList.rejected, (state) => {
        state.tintedLoading = false;
      })

      .addCase(getPpfList.pending, (state) => {
        state.ppfLoading = true;
      })
      .addCase(getPpfList.fulfilled, (state, action) => {
        state.ppfLoading = false;
        state.ppfPackageList =
          action.payload.data.packages?.length > 0 &&
          action.payload.data.packages[0].service === "ppf"
            ? groupProducts(action.payload.data.packages, "true")
            : [];
        state.ppfAlacarteList =
          action.payload.data.packages?.length > 0 &&
          action.payload.data.packages[0].service === "ppf"
            ? groupProducts(action.payload.data.packages, "false")
            : [];
      })
      .addCase(getPpfList.rejected, (state) => {
        state.ppfLoading = false;
      })

      .addCase(getWrappingList.pending, (state) => {
        state.wrappingLoading = true;
      })
      .addCase(getWrappingList.fulfilled, (state, action) => {
        state.wrappingLoading = false;
        state.wrappingPackageList =
          action.payload.data.packages?.length > 0 &&
          action.payload.data.packages[0].service === "wrapping"
            ? groupProducts(action.payload.data.packages, "false")
            : [];
      })
      .addCase(getWrappingList.rejected, (state) => {
        state.wrappingLoading = false;
      })

      .addCase(getCoatingList.pending, (state) => {
        state.coatingLoading = true;
      })
      .addCase(getCoatingList.fulfilled, (state, action) => {
        state.coatingLoading = false;
        state.coatingPackageList =
          action.payload.data.packages?.length > 0 &&
          action.payload.data.packages[0].service === "coating"
            ? groupProducts(action.payload.data.packages, "true")
            : [];
        state.coatingAlacarteList =
          action.payload.data.packages?.length > 0 &&
          action.payload.data.packages[0].service === "coating"
            ? groupProductsName(action.payload.data.packages, "false")
            : [];
      })
      .addCase(getCoatingList.rejected, (state) => {
        state.coatingLoading = false;
      })

      .addCase(getProductList.pending, (state) => {
        state.productLoading = true;
      })
      .addCase(getProductList.fulfilled, (state, action) => {
        state.productLoading = false;
        state.accessoriesList = action.payload.data.packages;
        state.accessoriesCount = action.payload.data.iTotalDisplayRecords;

        // const pathname = window.location.pathname
        //   ?.split("/")[2]
        //   ?.replace(/-/g, " ");

        // switch (pathname) {
        //   case "car tinted":
        //     state.tintedPackageList =
        //       action.payload.data.packages?.length > 0 &&
        //       action.payload.data.packages[0].service === "tinted"
        //         ? groupTintedProducts(action.payload.data.packages, "true")
        //         : [];
        //     state.tintedAlacarteList =
        //       action.payload.data.packages?.length > 0 &&
        //       action.payload.data.packages[0].service === "tinted"
        //         ? groupTintedProducts(action.payload.data.packages, "false")
        //         : [];
        //     break;
        //   case "car ppf":
        //     state.ppfPackageList =
        //       action.payload.data.packages?.length > 0 &&
        //       action.payload.data.packages[0].service === "ppf"
        //         ? groupProducts(action.payload.data.packages, "true")
        //         : [];
        //     state.ppfAlacarteList =
        //       action.payload.data.packages?.length > 0 &&
        //       action.payload.data.packages[0].service === "ppf"
        //         ? groupProducts(action.payload.data.packages, "false")
        //         : [];
        //     break;
        //   case "car wrapping":
        //     state.wrappingPackageList =
        //       action.payload.data.packages?.length > 0 &&
        //       action.payload.data.packages[0].service === "wrapping"
        //         ? groupProducts(action.payload.data.packages, "false")
        //         : [];
        //     break;
        //   case "car coating":
        //     state.coatingPackageList =
        //       action.payload.data.packages?.length > 0 &&
        //       action.payload.data.packages[0].service === "coating"
        //         ? groupProducts(action.payload.data.packages, "true")
        //         : [];
        //     state.coatingAlacarteList =
        //       action.payload.data.packages?.length > 0 &&
        //       action.payload.data.packages[0].service === "coating"
        //         ? groupProductsName(action.payload.data.packages, "false")
        //         : [];
        //     break;
        //   case "accessories":
        //     state.accessoriesList = action.payload.data.packages;
        //     break;
        //   default:
        //     state.accessoriesList = action.payload.data.packages;
        //     break;
        // }
      })
      .addCase(getProductList.rejected, (state) => {
        state.productLoading = false;
      })

      .addCase(getProductInfo.pending, (state) => {
        state.productInfoLoading = true;
      })
      .addCase(getProductInfo.fulfilled, (state, action) => {
        state.productInfoLoading = false;
        state.accessoriesInfo = action.payload.data;
      })
      .addCase(getProductInfo.rejected, (state) => {
        state.productInfoLoading = false;
      })

      .addCase(claimWindscreen.pending, (state) => {
        state.submitLoading = true;
      })
      .addCase(claimWindscreen.fulfilled, (state, action) => {
        state.submitLoading = false;
      })
      .addCase(claimWindscreen.rejected, (state) => {
        state.submitLoading = false;
      })

      .addCase(renewInsurance.pending, (state) => {
        state.submitLoading = true;
      })
      .addCase(renewInsurance.fulfilled, (state, action) => {
        state.submitLoading = false;
      })
      .addCase(renewInsurance.rejected, (state) => {
        state.submitLoading = false;
      });
  },
});

export const {
  setSelectedTintedSeries,
  setSelectedTintedType,
  // setSelectedTintedBodyType,
  setSelectedTintedPackage,
  clearAccessoriesInfo,
  setFilterPage,
  setFilterSearch,
  setFilterCategory,
} = dataSlice.actions;

export default dataSlice.reducer;
