import { createSlice } from "@reduxjs/toolkit";

export const initialData = {
  productList: [
    {
      name: "Title",
      price: 500,
      promo_price: 550,
    },
    {
      name: "Title",
      price: 500,
      promo_price: 550,
    },
    {
      name: "Title",
      price: 500,
      promo_price: 550,
    },
    {
      name: "Title",
      price: 500,
      promo_price: 550,
    },
    {
      name: "Title",
      price: 500,
      promo_price: 550,
    },
    {
      name: "Title",
      price: 500,
      promo_price: 550,
    },
  ],
};

const dataSlice = createSlice({
  name: "home",
  initialState: initialData,
  reducers: {},
  extraReducers: {},
});

export const {} = dataSlice.actions;

export default dataSlice.reducer;
