import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/element/button";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { addCart } from "../../../redux/reducer/cartOrderReducer";
import { useToast } from "../../../components/common/common.toast";
import { Skeleton } from "primereact/skeleton";
import { openLoginModal } from "../../../redux/reducer/modalReducer";
import { getCartCount } from "../../../redux/reducer/commonReducer";
import { DefaultJustint } from "../../../assets/images";
import formatNumber from "../../../helpers/format.number";

export default function CoatingPackage() {
  const toast = useToast();
  const dispatch = useDispatch();

  const { addCartLoading } = useSelector((state) => state.cart_order);
  const { selectedBodyType, token, username, selectedPackageOrAlacarte } =
    useSelector((state) => state.common);
  const { coatingPackageList, coatingLoading } = useSelector(
    (state) => state.service
  );

  const [packageList, setPackageList] = useState({});
  const [activePackage, setActivePackage] = useState("");

  const featureUnitList = {
    "Body Paint Coating": "layer(s)",
    "Hydrophobic Maintenance": "time(s)",
    "Jus-Tint Warranty": "year(s)",
  };

  const handleSelectPackage = (item) => {
    setActivePackage(item);
  };

  const handleAddCart = (id) => {
    if (token && username) {
      dispatch(
        addCart({
          product_id: [id],
          quantity: 1,
        })
      )
        .unwrap()
        .then((res) => {
          toast.success("Add to cart successfully.");
          dispatch(getCartCount());
        })
        .catch((ex) => {
          if (ex && ex.response?.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                toast.error(errors[item][0]);
              });
            }
          }
        });
    } else {
      dispatch(openLoginModal());
    }
  };

  useEffect(() => {
    if (coatingPackageList[selectedBodyType]) {
      setPackageList(coatingPackageList[selectedBodyType]);
      //    setActivePackage(coatingPackageList[selectedBodyType][0]?.name?.name)
    } else {
      setPackageList([]);
    }
  }, [coatingPackageList, selectedBodyType]);

  useEffect(() => {
    setActivePackage({});
  }, [selectedPackageOrAlacarte]);

  return (
    <>
      {selectedPackageOrAlacarte === "package" && (
        <section className="coating-package">
          <Swiper
            className="package-list"
            slidesPerView="auto"
            spaceBetween={20}
            grabCursor={true}
          >
            {!coatingLoading
              ? packageList?.length > 0 &&
                packageList.map((coating, coatingIndex) => (
                  <SwiperSlide
                    key={coatingIndex}
                    className={`package-item ${
                      activePackage === coating.name?.name ? "--active" : ""
                    }`}
                  >
                    <section
                      className="package-detail pointer"
                      onClick={() => handleSelectPackage(coating.name?.name)}
                    >
                      <article className="package-frame">
                        <LazyLoadImage
                          src={
                            coating.media?.length > 0
                              ? coating.media[0].url
                              : DefaultJustint
                          }
                          alt={coating.name?.name}
                        />
                      </article>
                      <article className="package-text">
                        <h6>{coating.name?.name}</h6>
                        <article className="feature-list">
                          {coating.name?.package_items?.length > 0 &&
                            coating.name.package_items.map(
                              (feature, featureIndex) => (
                                <article
                                  key={featureIndex}
                                  className="feature-item"
                                >
                                  <p>{feature.name}</p>
                                  <p className="--value">
                                    {feature.value === "No" ? (
                                      <Icon icon="octicon:no-entry-16" />
                                    ) : (
                                      feature.value
                                    )}
                                  </p>
                                  {/* {featureUnitList[feature.name]} */}
                                </article>
                              )
                            )}
                        </article>
                        <span>
                          *Additional polishing fees applicable for used car
                        </span>
                        {!isNaN(coating.price) && (
                          <h6 className="--price mt-2">
                            RM {formatNumber(parseFloat(coating.price))}
                          </h6>
                        )}
                      </article>
                    </section>
                    <Button
                      btnClassName={`add-cart-button coating ${
                        activePackage === coating.name.name
                          ? "--selected"
                          : "--none"
                      }`}
                      onClick={() => handleAddCart(coating.id)}
                      disabled={
                        addCartLoading && activePackage === coating.name.name
                      }
                    >
                      {!isNaN(coating.price) ? "Add to Cart" : "View Price"}
                    </Button>
                  </SwiperSlide>
                ))
              : Array.from({ length: 3 }, (_, ppfSkeletonIndex) => (
                  <SwiperSlide
                    key={ppfSkeletonIndex}
                    className="package-item"
                    style={{ height: "675px" }}
                  >
                    <Skeleton
                      width="100%"
                      height="100%"
                      className="package-detail"
                    />
                  </SwiperSlide>
                ))}
          </Swiper>
        </section>
      )}
    </>
  );
}
