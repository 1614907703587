import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/element/button";
import { Icon } from "@iconify/react";
import Drawer from "react-modern-drawer";
import FilterAccordion from "./filter.accordion";
import {
  closeFilterModal,
  openFilterModal,
} from "../../../redux/reducer/modalReducer";
import {
  setFilterBrand,
  setFilterModel,
  setFilterYear,
} from "../../../redux/reducer/commonReducer";

export default function AccessoriesFilter() {
  const dispatch = useDispatch();
  const { isOpenFilterModal } = useSelector((state) => state.modal);

  const currentYear = new Date().getFullYear();

  const toggleDrawer = () => {
    dispatch(closeFilterModal());
    dispatch(setFilterBrand([]));
    dispatch(setFilterModel([]));
    dispatch(setFilterYear([1990, currentYear]));
  };

  return (
    <>
      <section className="accessories-filter">
        <article
          className="flex justify-between items-center gap-3 pointer"
          onClick={() => dispatch(openFilterModal())}
        >
          <Icon icon="tabler:car-4wd" />
          <p>Filter</p>
          <Icon icon="teenyicons:down-solid" className="--drop" />
        </article>
      </section>
      <Drawer
        open={isOpenFilterModal}
        onClose={toggleDrawer}
        direction="left"
        className="drawer"
      >
        <FilterAccordion toggleDrawer={toggleDrawer} />
      </Drawer>
    </>
  );
}
