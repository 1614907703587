import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/element/button";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import { addCart } from "../../../redux/reducer/cartOrderReducer";
import { useToast } from "../../../components/common/common.toast";
import { Skeleton } from "primereact/skeleton";
import CommonViewPrice from "../../../components/common/common.view.price";
import CommonStep from "../../../components/common/common.step";
import { getCartCount } from "../../../redux/reducer/commonReducer";
import ChoosePackageOrAlacarte from "../../../components/common/choose.package.alacarte";

export default function CoatingAlacarte() {
  const toast = useToast();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const { token, username, selectedPackageOrAlacarte, selectedBodyType } =
    useSelector((state) => state.common);
  const { addCartLoading } = useSelector((state) => state.cart_order);
  const { coatingAlacarteTable, coatingAlacarteList, coatingLoading } =
    useSelector((state) => state.service);

  const [selectedAlacarte, setSelectedAlacarte] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  const handleSelectAlacarte = (id, price) => {
    setSelectedAlacarte((prevSelected) => {
      if (prevSelected.includes(id)) {
        setTotalPrice(totalPrice - parseFloat(price));
        return prevSelected.filter((alacarte) => alacarte !== id);
      } else {
        setTotalPrice(totalPrice + parseFloat(price));
        return [...prevSelected, id];
      }
    });
  };

  const handleAddCart = () => {
    dispatch(
      addCart({
        product_id: selectedAlacarte,
        quantity: 1,
      })
    )
      .unwrap()
      .then((res) => {
        toast.success("Add to cart successfully.");
        dispatch(getCartCount());
        setSelectedAlacarte([]);
        setTotalPrice(0);
      })
      .catch((ex) => {
        if (ex && ex.response?.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              toast.error(errors[item][0]);
            });
          }
        }
      });
  };

  const AddCartButton = ({ type }) => {
    return (
      <section className="mt-5">
        <article className={`alacarte-list ${type}`}>
          {!type ? <article></article> : ""}
          {token && username ? (
            <Button
              className="colspan-3"
              btnClassName="total-button coating"
              disabled={totalPrice === 0 || addCartLoading}
              onClick={() => handleAddCart()}
            >
              {!isNaN(totalPrice) ? (
                <h5>
                  Total RM {parseFloat(totalPrice).toLocaleString()} - Add to
                  cart
                </h5>
              ) : (
                <h5>View Price - Add to cart</h5>
              )}
            </Button>
          ) : (
            <CommonViewPrice className="colspan-3" />
          )}
        </article>
      </section>
    );
  };

  useEffect(() => {
    setTotalPrice(0);
    setSelectedAlacarte([]);
  }, [selectedPackageOrAlacarte]);

  return (
    <>
      <CommonStep
        no={2}
        isSelecting={true}
        title="Select Alacarte Or Package"
        subtitle="Available Packages Listed Below"
        style={{ marginBottom: "-2em" }}
      />
      <ChoosePackageOrAlacarte locateAt="coating" />
      {selectedPackageOrAlacarte === "alacarte" && (
        <section className="coating-alacarte">
          <article>
            <h5>
              Ala Carte <span>( Warranty 2 Years )</span>
            </h5>
            <article className="alacarte-scroll">
              <article className="alacarte-section">
                <section className="alacarte-list mt-5">
                  {coatingAlacarteTable.map(
                    (table, tableIndex) =>
                      (tableIndex === 0 ||
                        table.value === selectedBodyType) && (
                        <article
                          key={tableIndex}
                          className="alacarte-table-item"
                        >
                          <h5
                            className={
                              table.title !== "Type of Coating"
                                ? "text-center"
                                : ""
                            }
                          >
                            {table.title}
                          </h5>
                        </article>
                      )
                  )}
                </section>
                {!coatingLoading
                  ? coatingAlacarteList?.length > 0 &&
                    coatingAlacarteList.map((alacarte, alacarteIndex) => (
                      <section className="mt-5" key={alacarteIndex}>
                        <article className="alacarte-list">
                          <p>{alacarte.name}</p>
                          {selectedBodyType === "standard" && (
                            <Button
                              btnClassName={`alacarte-button coating ${
                                selectedAlacarte.includes(alacarte.standard_id)
                                  ? "--active"
                                  : ""
                              }`}
                              onClick={() =>
                                handleSelectAlacarte(
                                  alacarte.standard_id,
                                  alacarte.standard_price
                                )
                              }
                            >
                              {!isNaN(alacarte.standard_price)
                                ? `RM ${parseFloat(
                                    alacarte.standard_price
                                  ).toLocaleString()}`
                                : selectedAlacarte.includes(
                                    alacarte.standard_id
                                  )
                                ? "Selected"
                                : "Select"}
                            </Button>
                          )}
                          {selectedBodyType === "suv" && (
                            <Button
                              btnClassName={`alacarte-button coating ${
                                selectedAlacarte.includes(alacarte.suv_id)
                                  ? "--active"
                                  : ""
                              }`}
                              onClick={() =>
                                handleSelectAlacarte(
                                  alacarte.suv_id,
                                  alacarte.suv_price
                                )
                              }
                            >
                              {!isNaN(alacarte.suv_price)
                                ? `RM ${parseFloat(
                                    alacarte.suv_price
                                  ).toLocaleString()}`
                                : selectedAlacarte.includes(alacarte.suv_id)
                                ? "Selected"
                                : "Select"}
                            </Button>
                          )}
                          {selectedBodyType === "mpv" && (
                            <Button
                              btnClassName={`alacarte-button coating ${
                                selectedAlacarte.includes(alacarte.mpv_id)
                                  ? "--active"
                                  : ""
                              }`}
                              onClick={() =>
                                handleSelectAlacarte(
                                  alacarte.mpv_id,
                                  alacarte.mpv_price
                                )
                              }
                            >
                              {!isNaN(alacarte.mpv_price)
                                ? `RM ${parseFloat(
                                    alacarte.mpv_price
                                  ).toLocaleString()}`
                                : selectedAlacarte.includes(alacarte.mpv_id)
                                ? "Selected"
                                : "Select"}
                            </Button>
                          )}
                        </article>
                      </section>
                    ))
                  : Array.from({ length: 3 }, (_, alacarteSkeletonIndex) => (
                      <section className="mt-5" key={alacarteSkeletonIndex}>
                        <article className="alacarte-list">
                          <Skeleton
                            width="100%"
                            height="52px"
                            className="alacarte-button"
                          />
                          <Skeleton
                            width="100%"
                            height="52px"
                            className="alacarte-button"
                          />
                          <Skeleton
                            width="100%"
                            height="52px"
                            className="alacarte-button"
                          />
                          <Skeleton
                            width="100%"
                            height="52px"
                            className="alacarte-button"
                          />
                        </article>
                      </section>
                    ))}
                {width > 991 && <AddCartButton />}
              </article>
            </article>
            {width < 991 && <AddCartButton type="total" />}
          </article>
        </section>
      )}
    </>
  );
}
