export default function formatNumber(num, type) {
  if (type == "thousand") {
    var units = ["k", "M", "B", "T", "P", "E", "Z", "Y"];
    var decimal;

    num = parseFloat(num);

    for (var i = units.length - 1; i >= 0; i--) {
      decimal = Math.pow(1000, i + 1);

      if (num <= -decimal || num >= decimal) {
        const number = num / decimal;
        const formattedNumber = number.toFixed(2);

        return formattedNumber + units[i];
      }
    }

    return num.toFixed(2);
  } else {
    // return parseFloat(num).toFixed(2)
    const hasDecimal = num % 1 !== 0;

    return num.toLocaleString(
      undefined,
      hasDecimal
        ? {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        : {}
    );
  }

  return num;
}
