import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/element/button";
import { WholeCar } from "../../../assets/images";
import { useEffect, useState } from "react";
import { Form, Formik, useFormikContext } from "formik";
import { addCart } from "../../../redux/reducer/cartOrderReducer";
import { Skeleton } from "primereact/skeleton";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import { useToast } from "../../../components/common/common.toast";
import CommonViewPrice from "../../../components/common/common.view.price";
import CommonStep from "../../../components/common/common.step";
import { getCartCount } from "../../../redux/reducer/commonReducer";
import ChoosePackageOrAlacarte from "../../../components/common/choose.package.alacarte";
import formatNumber from "../../../helpers/format.number";

export default function PpfAlacarte() {
  const toast = useToast();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const { ppfAlacarteList, ppfLoading } = useSelector((state) => state.service);
  const { selectedBodyType, selectedPackageOrAlacarte, token, username } =
    useSelector((state) => state.common);
  const { addCartLoading } = useSelector((state) => state.cart_order);

  const [alacarteList, setAlacarteList] = useState([]);
  const [selectedAlacarte, setSelectedAlacarte] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  const handleSelectAlacarte = (alacarte, price) => {
    setSelectedAlacarte((prevSelected) => {
      if (prevSelected.includes(alacarte)) {
        setTotalPrice(totalPrice - parseFloat(price));
        return prevSelected.filter((item) => item !== alacarte);
      } else {
        setTotalPrice(totalPrice + parseFloat(price));
        return [...prevSelected, alacarte];
      }
    });
  };

  const handleAddCart = () => {
    dispatch(
      addCart({
        product_id: selectedAlacarte,
        quantity: 1,
      })
    )
      .unwrap()
      .then((res) => {
        toast.success("Add to cart successfully.");
        dispatch(getCartCount());
        setSelectedAlacarte([]);
        setTotalPrice(0);
      })
      .catch((ex) => {
        if (ex && ex.response?.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              toast.error(errors[item][0]);
            });
          }
        }
      });
  };

  useEffect(() => {
    if (ppfAlacarteList[selectedBodyType]) {
      setAlacarteList(ppfAlacarteList[selectedBodyType]);
    } else {
      setAlacarteList([]);
    }
  }, [ppfAlacarteList, selectedBodyType]);

  useEffect(() => {
    setTotalPrice(0);
    setSelectedAlacarte([]);
  }, [selectedPackageOrAlacarte]);

  return (
    <>
      <CommonStep
        no={2}
        isSelecting={true}
        title="Select Alacarte Or Package"
        subtitle="Available Packages Listed Below"
        style={{ marginBottom: "-3em" }}
      />
      <ChoosePackageOrAlacarte locateAt="ppf" />
      {selectedPackageOrAlacarte === "alacarte" && (
        <section className="ppf-alacarte">
          <article className="w-100">
            <h5>
              Ala Carte <span>( Warranty 2 Years )</span>
            </h5>
            <article className="alacarte-list mt-4">
              {!ppfLoading
                ? alacarteList?.length > 0 &&
                  alacarteList.map((ppf, ppfIndex) => (
                    <Button
                      key={ppfIndex}
                      btnClassName={`alacarte-button ${
                        selectedAlacarte.includes(ppf.id) ? "--active" : ""
                      }`}
                      onClick={() => handleSelectAlacarte(ppf.id, ppf.price)}
                    >
                      {ppf.name?.name}
                    </Button>
                  ))
                : Array.from({ length: 11 }, (_, ppfSkeletonIndex) => (
                    <Skeleton
                      height={width > 991 ? "62px" : "55px"}
                      className="alacarte-button"
                      key={ppfSkeletonIndex}
                    />
                  ))}
            </article>
            {!isNaN(totalPrice) && token && username ? (
              <Button
                className="w-100 flex justify-center"
                btnClassName="total-button ppf"
                disabled={totalPrice === 0 || addCartLoading}
                onClick={() => handleAddCart()}
              >
                <>
                  <h5>Total RM {formatNumber(totalPrice)}</h5>
                  <article className="total-divider" />
                  <p>Add to Cart</p>
                </>
              </Button>
            ) : (
              <CommonViewPrice />
            )}
          </article>
          <article>
            <article className="alacarte-image">
              <article className="car-frame">
                <img src={WholeCar} />
              </article>
              {alacarteList.map((ppf, ppfIndex) => (
                <article
                  className="accessories-frame"
                  key={ppfIndex}
                  style={{
                    display: selectedAlacarte.includes(ppf.id)
                      ? "block"
                      : "none",
                  }}
                >
                  <LazyLoadImage
                    src={ppf.media?.length > 0 && ppf.media[0].url}
                    alt={ppf.name?.name}
                  />
                </article>
              ))}
            </article>
          </article>
        </section>
      )}
    </>
  );
}
