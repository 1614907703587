import { useDispatch, useSelector } from "react-redux";
import { closeConfirmModal } from "../../redux/reducer/modalReducer";
import FullModal from "../modal/full.modal.box";
import ModalHeader from "../header/modal.header";
import { MDBModalBody, MDBModalFooter } from "mdb-react-ui-kit";
import Button from "../element/button";
import { Icon } from "@iconify/react";
import { setConfirmData } from "../../redux/reducer/commonReducer";

export default function ConfirmModal() {
  const dispatch = useDispatch();
  const { isOpenConfirmModal } = useSelector((state) => state.modal);
  const { confirmData } = useSelector((state) => state.common);
  const { removeCartLoading, clearCartLoading } = useSelector(
    (state) => state.cart_order
  );

  const handleCloseModal = () => {
    dispatch(closeConfirmModal());
    dispatch(setConfirmData(null));
  };

  return (
    <FullModal
      // centered
      staticBackdrop={true}
      show={isOpenConfirmModal}
      backButton={handleCloseModal}
      screenSize="md"
      className="overflow-hidden"
      contentClassName="br-34"
      content={
        <>
          <MDBModalBody>
            <section className="confirm-modal">
              <article className="confirm-icon">
                <Icon icon="solar:cart-cross-outline" />
              </article>
              <article>
                <h3>{confirmData?.title}</h3>
                <p>
                  {/* Are you sure you want to clear the cart? This action cannot be
                  undone. */}
                  {confirmData?.message}
                </p>
              </article>
            </section>
          </MDBModalBody>
          <MDBModalFooter className="p-0">
            <section className="confirm-button">
              <Button onClick={() => handleCloseModal()}>Cancel</Button>
              <Button
                btnClassName="--sure"
                disabled={removeCartLoading || clearCartLoading}
                onClick={() => {
                  if (confirmData?.action) {
                    confirmData.action(confirmData?.id);
                  }
                }}
              >
                Sure
              </Button>
            </section>
          </MDBModalFooter>
        </>
      }
    />
  );
}
