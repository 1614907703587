import { useSelector } from "react-redux";
import CartBody from "./components/cart.body";
import CartHeader from "./components/cart.header";
import EditPriceModal from "./modals/edit.price.modal";
import AccessoriesModal from "../../components/common/accessories.modal";
import scrollToTop from "../../helpers/scroll.to.top";
import { useEffect } from "react";
import ConfirmModal from "../../components/common/confirm.modal";
import ImageGallery from "../../components/common/image.gallery";

export default function Cart() {
  const {
    isOpenConfirmModal,
    isOpenEditPriceModal,
    isOpenAccessoriesModal,
    isOpenGallery,
  } = useSelector((state) => state.modal);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <section className="cart-page page-padding">
      <CartHeader />
      <CartBody />
      {isOpenConfirmModal && <ConfirmModal />}
      {isOpenEditPriceModal && <EditPriceModal />}
      {isOpenAccessoriesModal && <AccessoriesModal />}
      {isOpenGallery && <ImageGallery />}
    </section>
  );
}
