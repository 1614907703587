import { useDispatch, useSelector } from "react-redux";
import AccessoriesModal from "../../components/common/accessories.modal";
import AccessoriesBody from "./components/accessories.body";
import AccessoriesHeader from "./components/accessories.header";
import scrollToTop from "../../helpers/scroll.to.top";
import { useEffect } from "react";
import ImageGallery from "../../components/common/image.gallery";
import {
  getProductList,
  setFilterPage,
} from "../../redux/reducer/serviceReducer";

export default function Accessories() {
  const dispatch = useDispatch();
  const { isOpenAccessoriesModal, isOpenGallery } = useSelector(
    (state) => state.modal
  );

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    dispatch(setFilterPage(0));
    dispatch(
      getProductList({
        start: 0,
        length: 12,
        service: "accessory",
      })
    );
  }, []);

  return (
    <article
      className="accessories-page w-100 flex flex-column items-center"
      style={{ gap: "35px" }}
    >
      <AccessoriesHeader />
      <AccessoriesBody />
      {isOpenAccessoriesModal && <AccessoriesModal />}
      {isOpenGallery && <ImageGallery />}
    </article>
  );
}
